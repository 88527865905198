.buy {
    position: relative;
    background: $orangeColor;
    color: $colorWhite;
    left: 6px;
    font-family: $Roboto;
    font-weight: 500;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .buy:before {
    content: "";
    position: absolute;
    right: 100%;
    top: 0px;
    width: 0px;
    height: 0px;
    border-top: 18px solid transparent;
    border-right: 14px solid $orangeColor;
    border-bottom: 18px solid transparent;
  }

  .buy:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 0px;
    width: 0px;
    height: 0px;
    border-top: 18px solid transparent;
    border-left: 14px solid $orangeColor;
    border-bottom: 18px solid transparent;
  }
.btn:hover {
    color: $colorWhite;
}
.btn:focus, .form-control:focus {
    outline: none;
    box-shadow: none;
}
.time {
  background: url('../images/buy/bgSpan.png');
  background-position: center;
  background-repeat: no-repeat;
  padding: 17px;
}
.desclimer-modal {
  text-align: center !important;
  max-width: 650px !important;
  background: $blackColor !important;
  color: $colorWhite !important;
  margin: 10rem !important;
  .react-responsive-modal-closeButton {
    display: none;
  }
  h1 {
    margin-top: 2rem;
  }
  .btn {
    background: none;
    color: $orangeColor;
    float: right;
  }
  p {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}

.react-responsive-modal-modal
{
  overflow-y: visible!important;
}
.modal-1 {
  text-align: center !important;
  max-width: 650px !important;
  background: $blackColor !important;
  color: $colorWhite !important;
  margin: 10rem !important;
  border: 2px solid $orangeColor;
 
 
  .react-responsive-modal-closeButton {
    display: none;
  }

  .form-control {
    background: $gray 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: $colorWhite;
    width: auto;
    margin: auto;
    border: 1px solid $gray;
    text-align: center;
    display: block;
    padding: 1.3rem 0.3rem;
  }
  
  .form-control:focus{
    border-color: $orangeColor;
  }

  .active-btn{
    padding: 0.5rem 1.5rem;
  }
  .inactive {
    background: rgba($color: $orangeColor, $alpha: 0.49);
    color: rgba($color: $colorWhite, $alpha: 0.49);
    font-family: $Montserrat;
    font-weight: 600;
  }
}

.register {
  text-align: center !important;
  max-width: 900px !important;
  background: url('../images/overlay.png') !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  color: $colorWhite !important;
  margin-top: 9rem !important;
  
  .react-responsive-modal-closeButton {
    display: none;
  }

  h1 {
    font-family: $Montserrat;
    font-weight: bold;
    color: $orangeColor;
    @media (max-width: 767px){
      font-size: 1.5rem;
    }
  }
  p {
    color: rgba($color: $colorWhite, $alpha: 0.8);
    font-family: $Montserrat;
    font-weight: 300;
  }
  .cross {
    background: none;
    color: $colorWhite;
    float: right;
  }
  .input-group {
    width: 90%;
    margin: 0 auto;
    margin-top: 1rem;
  }
  .input-div {
    color: $colorWhite;
    border: 0;
    position: relative;
    left: 0.8rem;
    @media (max-width:1275px) {
      width: 65%;
    }
  }
  .input-div::before {
    content: "";
    position: absolute;
    right: 100%;
    top: 0px;
    width: 0px;
    height: 0px;
    border-top: 18px solid transparent;
    border-right: 14px solid rgba($color: $colorWhite, $alpha: 0.14);
    border-bottom: 18px solid transparent;
  }
  .form-control {
    background-color: rgba($color: $colorWhite, $alpha: 0.14);
    border: 0;
    color: $colorWhite;
  }

  .main-div {
    display: flex;
    justify-content: center;
    align-items: center;
    
    .left {
      border: 1px solid $orangeColor;
      background-color: rgba($color: $colorWhite, $alpha: 0.14);
      width: 50%;
      padding: 2rem;
      margin-top: -5px;
      border-radius: 0.2rem;
      img {
        margin-bottom: 1rem;
      }
      span {
        color: rgba($color: $colorWhite, $alpha: 0.8);
        font-size: 0.8rem;
      }
    }
    .main-right {
      width: 20rem;
    }
    .right {
      border: 1px solid $orangeColor;
      border-radius: 0.2rem;
      margin-bottom: 0.3rem;
        margin-left: 0.5rem;
        background-color: rgba($color: $colorWhite, $alpha: 0.14);

        p {
          font-weight: bold;
          font-size: 20px;
        }
      .btn {
        margin-bottom: 0.5rem;
        background-color: $blueColor;
      }
      .btn::before {
        border-right: 14px solid $blueColor;
      }
      .btn::after {
        border-left: 14px solid $blueColor;
      }
      
      img {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      }
     
    }
    @media (max-width: 767px) {
      display: block;
     
      .left,.main-right,  .input-group {
        width: auto;
        margin-bottom: 0.2rem;
      }
      .left {
        padding: 1rem ;
      }
      .right {
        margin-left: 0;
      }
     
    }
  }
}
.video-modal {
  background: none !important;
    margin-top: 12rem !important;
    max-width: 1200px !important;
  @media (max-width: 767px) {
    margin: 0 !important;
    margin-left: -10px !important;
    margin-top: 12rem !important;
  }
  .youtube-div {
    width: 1175px;
    height: 500px;
    @media (max-width: 1400px) {
      width: 800px;
    }
    @media (max-width: 767px) {
      width: 350px;
      height: 300px;
    }
  }

}
@keyframes customEnterOverlayAnimation {
  0% {
    transform: translateY(70%);
    transition: 2s;

  }
  100% {
    transform: translateY(0);
    transition: 2s;

  }
}
@keyframes customLeaveOverlayAnimation {
  0% {
    transform: translateY(0);
    transition: 21s;

  }
  100% {
    transform: translateY(100%);
    transition: 2s;

  }
}

@keyframes customEnterModalAnimation {
  0% {
    transform: translateY(70%);
    transition: 2s;
  }
  100% {
    transform: translateY(0);
    transition: 2s;
  }
}
@keyframes customLeaveModalAnimation {
  0% {
    transform: translateY(0);
    transition: 1s;
  }
  100% {
    transform: translateY(100%);
    transition: 2s;
  }
}
// .Toastify__toast--success {
//   background-color: $orangeColor !important;
// }

.loader-div {
  background-color: $royalBlue;
  .mob-vid {
    display: none;
  }
  @media (max-width: 767px) {
    .web-vid {
      display: none;
    }
    .mob-vid {
  display: initial;
  height: 50rem;
    }
  }
 
}
.active-btn {
  background: $orangeColor !important;
  color: $colorWhite !important;
  padding: 0.67rem 1.5rem;
}
.time-para {
  span {
   background: $orangeColor !important;
   color: $colorWhite !important;
   padding: 0.5rem;
   border-radius: 0.3rem;
  }
 }
.rc-checkbox-input:focus+.rc-checkbox-inner {
  border: 0;
}

.rc-checkbox,
.rc-checkbox-inner {
  border-radius: 4px;
  background-color: $colorWhite;
  padding-top: 2px;
  padding-right: 2px;
  padding-left: 2px;

  &:after {
    border: none !important;
  }
}

.rc-checkbox-checked {
  .rc-checkbox-inner {
    background-color: $orangeColor !important;
    border: 1px solid $orangeColor !important;
    width: 12px;
    height: 12px;
  }
}
.accordion-body
{
  color: black!important;
}
.accordion-item
{
  border: none!important;
}