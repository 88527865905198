.market-place {
  color: $colorWhite;

  .main {
    // background: url('../../images/market/bck.png');
    background-color: black;
    // background-position: center;
    // font-family: $Montserrat;
    margin-top: 5rem;
    text-align: center;

    .first-top {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-top: -1rem;
      .toggle-div {
        margin: 0 auto;
        margin-bottom: 1rem;
      }
    }
    .inactive {
      margin-bottom: 1rem;
      background: rgba($color: $orangeColor, $alpha: 0.49);
      color: rgba($color: $colorWhite, $alpha: 0.49);
      font-family: $Montserrat;
      font-weight: 600;
    }
    .detail-para {
      width: 65%;
      margin: 0 auto;
      margin-bottom: 1rem;
      font-size: 0.8rem;
      @media (max-width: 767px) {
        width: auto;
      }
    }
    .first-box {
      border-radius: 0.3rem;
      background: $royalBlue;
      border: 3px solid $orangeColor;
      margin-left: 1rem;
      padding: 0.4rem;
      @media (min-width: 1450px) {
        width: 80%;
        margin: 0 0 0 auto;
      }
      .active {
        border: 2px solid $orangeColor;
        border-radius: 0.5rem !important;
      }
      .track-vertical {
        height: 20rem !important;
        background: $royalBlue !important;
        right: 0%;

        div {
          border-radius: 0.3rem !important;
          height: 9rem !important;
          background: $orangeColor !important;
        }
      }
      .heading {
        margin: 0 auto;
        margin-top: -1.4rem;
        background-color: $orangeColor;
        border-radius: 0.2rem;
        width: 84%;
        padding: 0.7rem;
        font-family: $Montserrat;
        font-weight: 600;
        margin-bottom: 0.5rem;

        @media (max-width: 1200px) {
          font-size: 0.8rem;
          width: 80%;
        }
      }

      .sub-box {
        background-color: $blackColor;
        margin: 0.2rem;
        border-radius: 0.2rem;
        cursor: pointer;

        img {
          width: 100%;
        }

        p {
          background-color: $orangeColor;
          color: $colorWhite;
          margin-bottom: 0;
          font-size: 0.8rem;
          padding: 0.2rem;
          border-bottom-left-radius: 0.2rem;
          border-bottom-right-radius: 0.2rem;

          @media (max-width: 1200px) {
            font-size: 0.8rem;
          }
        }
      }
    }

    .sec-box {
      background: $royalBlue;
      border-radius: 0.3rem;
      border: 3px solid $orangeColor;
      margin-left: 1rem;
      margin-top: 1rem;
      display: flex;
      align-items: center;
      text-align: left;
      padding: 0.6rem;
      @media (min-width: 1450px) {
        width: 80%;
        margin: 0 0 0 auto;
        margin-top: 1rem;
      }

      h5 {
        margin-top: 2rem;
        font-family: $Montserrat;
        font-weight: 600;
        text-transform: capitalize;
      }

      p {
        font-size: 0.8rem;
      }
    }

    .scroll {
      > :nth-of-type(1) {
        margin-right: -12px !important;
      }
    }
    .top-div {
      margin-top: 4rem;
      margin-bottom: 1.2rem;
      display: flex;
      justify-content: flex-end;
      margin-right: 1rem;
      .coins-detail {
        display: none;
        position: absolute;
        top: 9.5%;
        left: 60%;
        width: 30%;
        background: $royalBlue;
        text-align: center;
        border-radius: 0.3rem;
        z-index: 1;
        padding: 1rem;

        &:hover {
          display: initial;
        }
      }

      .gems-detail {
        display: none;
        position: absolute;
        top: 9.5%;
        left: 65%;
        width: 30%;
        background: $royalBlue;
        text-align: center;
        border-radius: 0.3rem;
        z-index: 1;
        padding: 1rem;

        &:hover {
          display: initial;
        }
      }

      .first-score {
        &:hover {
          ~ .coins-detail {
            display: initial;
          }
        }
      }

      .sec-score {
        &:hover {
          .gems-detail {
            display: initial;
          }
        }
      }

      .score-div {
        display: flex;
        align-items: center;
        margin-right: 2rem;
        img {
          position: relative;
          left: 11px;
          z-index: 1;
        }

        .body {
          background: $orangeColor;
          position: relative;
          display: flex;
          padding: 7px;
          width: 68%;
          border-radius: 0.3rem;
          justify-content: space-around;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;

          span {
            margin-left: 3px;
          }

          p {
            margin-bottom: 0;
          }
        }

        .body:after {
          content: "";
          position: absolute;
          left: 100%;
          top: 0px;
          width: 0px;
          height: 0px;
          border-top: 18px solid transparent;
          border-left: 18px solid $orangeColor;
          border-bottom: 20px solid transparent;
        }
      }
    }

    .filter {
      cursor: pointer;
    }

    .filter-box {
      position: absolute;
      right: 3%;
      top: 24%;
      width: 94%;
      background-color: $blackColor;
      padding: 2rem;
      z-index: 1;
      text-align: initial;

      .sub-filter {
        display: flex;

        .first-div {
          width: 20%;
        }

        .sec-div {
          width: 30%;

          .rc-slider-tooltip {
            display: none !important;
          }
        }

        h5 {
          margin-bottom: 1rem;
          font-family: $Montserrat;
          font-weight: bold;
        }

        .rc-slider-disabled {
          background-color: $blackColor !important;
        }

        .typ-heading {
          margin-top: 2rem;
        }

        label {
          display: flex;
          align-items: baseline;

          div {
            margin-left: 0.5rem;
            font-family: $Roboto;
            font-weight: 300;
            font-size: 0.9rem;
            color: rgba($color: $colorWhite, $alpha: 0.8);
          }
        }

        .rc-slider {
          width: 84%;
          margin: 0 auto;
        }

        .rc-slider-rail {
          background-color: rgba($color: $colorWhite, $alpha: 0.2);
        }

        .rc-slider-track {
          background-color: $orangeColor !important;
        }

        .rc-slider-handle {
          border-radius: 0.3rem;
          background-color: $orangeColor;
          border: solid 2px $orangeColor;
          box-shadow: none !important;
          width: 17px;
          height: 19px;
          margin-top: -7px;
        }

        .range-div {
          display: flex;
          justify-content: space-between;
          font-size: 0.6rem;
          color: rgba($color: $colorWhite, $alpha: 0.8);
          margin: 0 auto;
          margin-top: 0.5rem;
          margin-bottom: 2rem;
          width: 84%;
        }

        .inputs-div {
          display: flex;
          justify-content: center;
          margin-bottom: 1rem;
          align-items: baseline;

          span {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
          }
        }

        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .last-div {
          width: 40%;
          margin: 0 auto;

          div {
            display: flex;
            justify-content: space-between;
            margin-bottom: 2rem;
            letter-spacing: 0.9px;
            font-size: 0.9rem;
            color: rgba($color: $colorWhite, $alpha: 0.8);

            span {
              width: 20%;
            }

            .rc-slider {
              width: 54%;
              margin: 0 auto;
            }

            .rc-slider-tooltip {
              top: -12px !important;

              .rc-slider-tooltip-inner {
                background: none !important;
                padding: 7px 8px;
                font-size: 0.7rem;
                line-height: 1.5;
                box-shadow: none;
              }
            }

            .rc-slider-tooltip-arrow {
              display: none !important;
            }
          }
        }
      }

      .btn {
        display: block;
        margin: 0 auto;
        color: $colorWhite;
        left: 0;
      }
    }
    .row-3d {
      @media (min-width: 1450px) {
        width: 90%;
        margin-right: 0;
      }
    }
    .main-box {
      border-radius: 0.3rem;
      background: $royalBlue;
      border: 3px solid $orangeColor;
      margin-left: 1rem;
      margin-right: 1rem;
      padding: 1rem;

      .track-vertical {
        height: 20rem !important;
        background: $royalBlue !important;
        right: 0%;
        border-radius: 0.3rem;

        div {
          height: 13rem !important;
          background: $orangeColor !important;
        }
      }
      .loader {
        display: block;
        margin: 0 auto;
        margin-top: 3rem;
      }

      @media (max-width: 1220px) {
        margin-right: 0.5rem;
      }
      @media (min-width: 1450px) {
        width: 90%;
        margin-right: 0;
      }
      .filters-div {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        .advnace-div {
          display: flex;
          justify-content: space-evenly;
          width: 36%;
          @media (min-width: 1400px) {
            width: 24%;
          }
        }
        p {
          font-size: 0.9rem;
        }

        img {
          margin-left: 0.3rem;
        }
      }

      .form-control {
        background: rgba($color: $pinkColor, $alpha: 0.14);
        color: $colorWhite;
        border: 0;
        position: relative;
      }

      .input-group {
        width: 50%;
      }

      ::placeholder {
        font-size: 0.9rem;
      }

      .input-div {
        color: $colorWhite;
        border: 0;
        position: relative;
        left: 0.8rem;
        width: 80%;

        @media (max-width: 1275px) {
          width: 68%;
        }
      }

      .input-div::before {
        content: "";
        position: absolute;
        right: 100%;
        top: 0px;
        width: 0px;
        height: 0px;
        border-top: 18px solid transparent;
        border-right: 14px solid rgba($color: $pinkColor, $alpha: 0.14);
        border-bottom: 18px solid transparent;
      }

      .sub-box {
        display: flex;
        align-items: center;
        border: 1px solid $orangeColor;
        margin-bottom: 0.5rem;
        box-shadow: 0px 3px 6px #00000029;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        background: $blackColor;
        border-radius: 0.25rem;
        cursor: pointer;
        padding: 0.3rem 0 0.3rem 0;
        .line {
          display: block;
          width: 1px;
          height: 5rem;
          background: $orangeColor;
        }
        .profile {
          width: 5rem;
          height: 5rem;
        }

        .right {
          width: 85%;
          padding: 0.6rem;
        }

        .sub-right {
          display: flex;
          justify-content: space-between;
          text-align: initial;
          align-items: baseline;

          img {
            width: 1rem;
            margin-right: 4px;
            margin-top: -2px;
          }
          .fire {
            width: 1.5rem;
            margin-top: -0.5rem;
          }

          .type-div {
            // width: 30%;
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          h5 {
            font-size: 1rem;
            font-family: $Montserrat;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @media (max-width: 1300px) {
              font-size: 0.6rem;
            }
          }

          p {
            margin-bottom: 0;
            font-weight: bold;
            @media (max-width: 1220px) {
              font-size: 0.8rem;
            }
          }

          span {
            font-size: 0.7rem;
            color: rgba($color: $colorWhite, $alpha: 0.7);
            font-weight: 400;

            @media (max-width: 1220px) {
              font-size: 0.5rem;
            }
          }
        }
      }
    }
    .load-div {
      background: $royalBlue;
    }
    .bottom-div {
      display: flex;
      text-align: initial;
      background: $orangeColor;
      margin-left: 1rem;
      margin-right: 1rem;
      margin-bottom: 3rem;
      margin-top: 1rem;
      border-radius: 0.3rem;
      padding: 1rem 0.6rem 1rem 2rem;
      // justify-content: space-around;
      align-items: center;
      @media (min-width: 1450px) {
        width: 90%;
        margin-right: 0;
      }
      .btn {
        display: none;
      }
      div {
        width: 80%;
      }

      .dics {
        margin-bottom: 0;
        font-size: 0.8rem;
        width: 90%;
      }
    }
    .time-para {
      width: auto;
      margin-bottom: 0;
      font-size: 1.3rem;

      span {
        background: $blackColor;
        padding: 0.5rem;
        border-radius: 0.4rem;
      }
    }

    h5 {
      font-family: $Montserrat;
      font-weight: 600;
    }
    .video {
      display: block;
      margin: 0 auto;
      width: 100%;
    }
    .detail-box {
      border-radius: 0.3rem;
      background: $royalBlue;
      border: 3px solid $orangeColor;
      margin-left: 1rem;
      margin-right: 1rem;
      padding: 1.5rem;

      @media (max-width: 1400px) {
        padding: 1rem;
        h3 {
          font-size: 1rem;
        }
      }

      .heading-div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;

        img {
          width: 2.3rem;
          cursor: pointer;
        }
      }

      .btn {
        left: 0;
        top: 0.8rem;
      }

      .description-div,
      .skills-div,
      .auction-div {
        background: rgba($color: $blackColor, $alpha: 0.27);
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 5px;
        text-align: initial;
        padding: 1rem;
        margin-bottom: 1rem;
        font-size: 0.9rem;
        @media (max-width: 1200px) {
          padding: 0.5rem;
        }
      }

      .skills-div {
        text-align: center;
        div {
          display: flex;
          justify-content: space-between;
          width: 95%;
          margin: 0 auto;

          img {
            width: 1rem;
          }

          .first {
            margin-left: 0.5rem;
          }
          .last-span {
            margin-left: 0.5rem;
            margin-top: 0.3rem;
            display: inline-block;
          }
          .light {
            background: $lightOrangeColor;
          }
        }
      }

      .auction-div {
        text-align: center;
        .detail {
          display: flex;
          justify-content: space-evenly;
          flex-flow: row wrap;

          h5 {
            font-size: 1rem;
          }
          @media (max-width: 1200px) {
            h5 {
              font-size: 0.8rem;
            }
          }
          span {
            margin-bottom: 0.5rem;
            display: block;
          }
          img {
            width: 1.2rem;
          }
        }
      }
    }

    .loader-image {
      display: block;
      width: 50%;
      margin: 0 auto;
    }

    .d-div {
      background: url("../../images/market/bg3d.jpg");
      background-position: center;
      border: 3px solid $orangeColor;
      border-radius: 0.2rem;
      width: 98%;

      .icon {
        position: absolute;
        z-index: 1;
        right: 8%;
        top: 5%;
        width: 16%;
      }

      .loader-3d {
        padding-top: 10rem;
      }

      div {
        border-radius: 0.2rem;
        height: 35.5rem !important;
        margin: 0 auto;
      }
    }

    .mob {
      display: none;
    }

    @media (max-width: 767px) {
      background: url("../../images/market/mobBack.svg");
      background-color: $blackColor;
      background-size: 100%;
      margin-top: 11rem;
      .mob {
        display: initial;
        margin-bottom: 2rem;
      }
      .web {
        display: none;
      }
      .detail-box {
        padding: 0.5rem;
        width: 95%;
        margin: 0 auto;
        .heading-div {
          margin-top: 1rem;
          align-items: baseline;
          img {
            width: 1.8rem;
          }
        }
        .btn {
          margin-bottom: 1rem;
        }
      }
      .d-div {
        width: 95% !important;
      }
      .first-box,
      .main-box {
        width: 95%;
        margin: 0 auto;
        margin-bottom: 1rem;
        border: 2px solid $orangeColor;
      }
      .sec-box {
        display: none !important;
      }
      .sec-box {
        display: block;
        text-align: center;
        h5 {
          margin-top: 0;
        }
      }
      .advnace-div {
        justify-content: flex-end !important;
        width: auto !important;
        p {
          margin-right: 0.5rem;
        }
      }
      .main-box {
        padding: 0.2rem;
      }
      .top-div {
        margin-bottom: 3rem;
        justify-content: center !important;
      }
      .filters-div {
        display: block !important;
        text-align: initial;

        .input-group {
          width: auto;
          margin-left: 0.7rem;
        }
      }

      .scroll-right,
      .scroll {
        div {
          margin-right: 0 !important;
          margin-left: 0 !important;
          margin-bottom: 0 !important;
        }
        .sub-box {
          margin-bottom: 0.5rem !important;
          margin-left: 0.2rem !important;
        }
      }
      .scroll {
        height: 200px !important;
      }

      .track-vertical {
        width: 3px !important;
      }
      .first-top {
        display: block;
        margin-top: 4rem;
      }
      .top-div {
        margin-top: 0;
        margin-bottom: 2rem;
        .score-div,
        .coins-detail,
        .gems-detail {
          width: auto;
          left: 0%;
          top: 24%;
        }
      }

      .bottom-div {
        display: block;
        position: fixed;
        bottom: 0;
        left: 3%;
        z-index: 1111111111111;
        width: 95%;
        margin: 0 auto;
        text-align: center;
        .btn {
          display: initial;
          position: absolute;
          right: -3%;
          top: -4%;
        }
        div {
          width: auto;
          margin-bottom: 0.8rem;
          margin-top: 0.5rem;
          p {
            width: auto !important;
          }
        }
      }

      .d-div {
        width: 92%;
        margin: 0 auto;
        div {
          height: 25rem !important;
        }
      }
      .sub-filter {
        display: block !important;
        .first-div {
          display: flex;
          justify-content: space-between;
          width: auto !important;
          .typ-heading {
            margin-top: 0 !important;
          }
        }
        .sec-div {
          width: auto !important;
        }
        .last-div {
          width: auto !important;
        }
      }
    }

    @media screen and (max-width: 1400px) and (min-width: 1024px) {
      margin-top: 8rem;
    }
  }
  .hide {
    display: none;
  }
}
