.first-bar {
  background: $blackColor 0% 0% no-repeat padding-box;
  padding: 0;

  .sub {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;

    a {
      color: $colorWhite;

      img {
        margin-right: 0.5rem;
      }
    }

    div {
      width: 50%;
      padding: 1rem 0 1rem 0;
      text-align: center;

      @media (max-width: 767px) {
        width: 34%;
      }
    }

    .pan-div {
      &:hover {
        background: $blushColor;

        a {
          color: $blackColor;
        }
      }

      @media (max-width: 767px) {
        display: flex;
        width: 59%;
        flex-wrap: wrap;
        /* justify-content: center; */
        justify-content: space-between;
        padding: 0;
        margin-top: 11px;

        img {
          width: 5.5rem;
        }

        .hide {
          display: none;
        }
      }
    }

    .dex-div {
      &:hover {
        background: $lightBlushColor;

        a {
          color: $blackColor;
        }
      }
    }

    .pankake-img {
      margin-right: 1rem;

      img {
        margin-top: -0.2rem;
      }
    }

    span {
      display: none;
    }

    @media (max-width: 767px) {
      width: 95%;
      font-size: 0.8rem;
      align-items: center;
      margin-top: -0.5rem;

      span {
        display: block;
        width: 2px;
        height: 2.5rem;
        background: $colorWhite;
      }

      img {
        width: 4rem;
        margin-right: 0rem !important;
        display: block;
        margin: 0 auto !important;
        margin-top: -3px;
      }

      .pankake-img {
        img {
          // width: 1.5rem;
          margin-top: 0;
        }
      }
    }
  }
}

.bar {
  background: black 0% 0% no-repeat padding-box;
  padding: 1rem 0 1rem 0;
  margin-top: 3.6rem;

  @media (max-width: 767px) {
    margin-top: 4rem;
  }

  .first-div {
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: space-between;
    font-family: $Roboto;
    font-weight: 500;

    @media (max-width: 767px) {
      display: block;
      margin-right: 0;
      margin: 0;
    }

    .game {
      font-size: 0.8rem;
      left: -20px !important;

      img {
        width: 1.5rem;
      }
    }

    .img-div {
      display: flex;
      align-items: baseline;

      img {
        margin-left: 1rem;
        width: 2rem;
        @media (max-width: 850px) {
          width: 1.4rem;
        }
        @media (max-width: 760px) {
          width: 2rem;
        }
        @media (max-width: 370px) {
          width: 1.7rem;
        }
      }
    }

    .btn {
      color: $orangeColor;

      @media (max-width: 767px) {
        display: none !important;
      }
    }

    .dropdown {
      @media (max-width: 767px) {
        .btn {
          display: initial !important;
        }
      }
    }

    .buy {
      color: $colorWhite;
      left: -5px;
    }

    p {
      margin-bottom: 0;
      color: #ffffff;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .drop-div {
    display: flex;

    .dropdown-menu {
      background-color: $blackColor;
      z-index: 11111111111111111;

      a {
        color: $colorWhite;
      }
    }
  }
}

.bar::after {
  content: "";
  position: absolute;
  left: 23%;
  bottom: -190%;
  width: 55%;
  height: 0;
  color: $colorWhite;
  border: 2rem solid transparent;
  border-top-color: $zincColor;

  @media (max-width: 767px) {
    width: 75%;
    left: 13%;
  }
}

.link-para {
  position: fixed;
  z-index: 1111111111;
  color: white;
  text-align: center;
  width: 100%;
  top: 12.5rem;
  font-size: 0.9rem;
  font-weight: bold;

  span {
    font-weight: normal;
  }

  img {
    cursor: pointer;
    margin-left: 0.5rem;
    width: 0.8rem;
  }

  @media (max-width: 767px) {
    top: 13rem;
    font-size: 0.8rem;

    img {
      width: 0.8rem;
    }

    span {
      display: none;
    }
  }
}

.dark {
  border-top: 1px solid #212121;
  background: $blackColor 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 4.5rem;
  width: 100%;
  margin-top: 7.8rem;
  font-family: $Roboto;

  .dropdown {
    top: -4rem;
    left: -15%;
    z-index: 1111111111111;

    @media (max-width: 767px) {
      left: 0;
      top: 0;
    }
  }

  .dropdown-menu {
    background-color: $blackColor;
    left: -9rem;
    border-radius: 1rem;
    padding: 1.5rem 0;

    a {
      color: $colorWhite;
      font-family: $Roboto;
      font-weight: 400;

      &:hover {
        background: none;
      }
    }

    .disconnect {
      margin-top: 5rem;
    }
  }

  // .navbar-brand {
  //   // background: #000000 0% 0%;
  //   position: relative;
  //   top: -38px;
  //   z-index: 1;

  //   @media (max-width: 767px) {
  //     width: 50% !important;
  //     position: fixed;
  //     top: auto;
  //     transform: translate(-50%, -50%);
  //     left: 50%;
  //     z-index: 1;
  //   }

  //   img {
  //     background: #000000 0% 0%;
  //     width: 60%;
  //   }
  // }

  .navbar-brand {
    position: relative;
    top: -38px;
    z-index: 1;
    img {
      width: 50%;
      background: #000000 0% 0%;
      margin: auto;
    }
    @media (max-width: 1120px) {
      position: relative;
      top: -14px;
      z-index: 1;
      img {
        width: 68%;
      }
    }
    @media (max-width: 930px) {
      position: relative;
      top: -18px;
      z-index: 1;
      img {
        width: 68%;
      }
    }
    @media (max-width: 760px) {
      position: relative;
      top: -22px;
      z-index: 1;
      img {
        width: 100%;
      }
    }
    @media (max-width: 500px) {
      width: 60% !important;
      position: relative;
      top: -15px;
      z-index: 1;
      img {
        width: 63%;
      }
    }
    @media (max-width: 380px) {
      width: 60% !important;
      position: relative;
      top: -18px;
      z-index: 1;
      img {
        width: 85%;
      }
    }
  }

  .mob-view {
    display: none;
  }

  .navbar-toggler {
    position: absolute;
    top: -80%;
    right: 2%;
  }

  .navbar-toggler:focus {
    outline: none;
  }

  .navbar-toggler-icon {
    background: url("../../images/toggler.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }

  .navbar-collapse {
    width: 34% !important;

    @media (max-width: 767px) {
      background: $blackColor 0% 0% no-repeat padding-box;
      z-index: 222222;
      margin-top: 3.3rem;
      text-align: center;
      padding-bottom: 1rem;

      .mob-view {
        display: initial;
      }

      .connect {
        color: $orangeColor;
      }
    }
  }

  .active {
    display: block;
    position: absolute;
    top: 4.8rem;
    margin-right: 24px;
    z-index: 1;
  }

  .nav-item {
    color: #ffffff;
    margin: auto 0;
    margin-left: 2rem;
    margin-top: 1.3rem;
    .binance-img {
      width: 0.9rem;
    }
    &:hover {
      text-decoration: none;
    }

    @media (max-width: 1330px) {
      margin-left: 1rem;
      font-size: 0.8rem;
    }

    @media (max-width: 767px) {
      margin-left: 0;
    }

    a {
      @include magic-border(2px, $orangeColor, 0.3s, 0);
      cursor: pointer;
      z-index: 1111111;
      padding: 6px;
      color: #ffffff;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .mob-view {
    &:hover {
      background: none;
    }
  }

  .first-item {
    margin-left: 30px;
    mix-blend-mode: hard-light;

    a {
      margin-left: -4rem;

      img {
        margin-top: -8px;
      }
    }
  }

  .coming {
    position: relative;
    visibility: hidden;
    background: $orangeColor;
    color: $colorWhite;
    top: -22px;
    right: -32px;
    padding: 0rem;
    font-size: 0.7rem;
  }

  .left,
  .web {
    // margin-top: 1rem;

    .mobile-img {
      @media (max-width: 767px) {
        width: 6%;
      }
    }

    .scroller {
      cursor: pointer;
    }

    .line {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .right {
    display: none;

    @media (max-width: 767px) {
      display: flex;
    }
  }
  @media (max-width: 767px) {
    margin-top: 8.2rem;
    .nav-item {
      font-size: 1rem;
    }
  }
}

.dark::after {
  content: "";
  display: none;
  position: absolute;
  left: 10%;
  bottom: -50%;
  width: 80%;
  height: 0;
  border: 1rem solid transparent;
  border-top-color: $blackColor;
}
