.game {
    .main {
        background: url('../../images/game/bck.jpg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        text-align: center;
        color: $colorWhite;
        margin-top: 8rem;
        font-family: $Roboto;
        h5 {
          margin-top: 7rem;
          font-weight: 400;
          font-size: 1.2rem;
        }
        h1 {
          font-weight: bold;
        }
        p {
          width: 30%;
          font-weight: 400;
          margin: 0 auto;
        }
        .time-p {
          margin-top: 2rem;
          margin-bottom: 16rem;

        }
        .hide {
          visibility: hidden;
        }
        @media(max-width: 767px) {
          background: url('../../images/game/mobBack.png');
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center;
          margin-top: 7rem;
          h5 {
            margin-top: 8rem;
          }
          h1 {
            font-size: 1.7rem;
          }
          p {
            width: 95%;
          }
        }
    }
}