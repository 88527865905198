.footer {
  position: relative;
  background: black;
  color: $colorWhite;
  width: 100%;
  font-family: $Roboto;
  @media (max-width: 767px) {
    text-align: center;
  }
  li {
    margin-bottom: 0.8rem;

    a {
      color: $colorWhite;
    }
  }

  h5 {
    font-weight: bold;
    @media (max-width: 1200px) {
      font-size: 1.1rem;
    }
  }
  .scroller {
    cursor: pointer;
  }
  .form-control {
    background: rgba($color: $pinkColor, $alpha: 0.14);
    color: $colorWhite;
    border: 0;
    position: relative;
  }
  .input-div {
    color: $colorWhite;
    border: 0;
    position: relative;
    left: 0.8rem;
    @media (max-width: 1275px) {
      width: 68%;
    }
  }
  .input-div::before {
    content: "";
    position: absolute;
    right: 100%;
    top: 0px;
    width: 0px;
    height: 0px;
    border-top: 18px solid transparent;
    border-right: 14px solid rgba($color: $pinkColor, $alpha: 0.14);
    border-bottom: 18px solid transparent;
  }
  .btn {
    left: 0.8rem;
    @media (max-width: 1200px) {
      font-size: 0.7rem;
    }
  }
  .social {
    img {
      margin-left: 0.3rem;
      width: 2rem;
    }
  }
  .mashroom-img {
    position: relative;
    display: none;
    left: 76%;
    top: 55px;
    width: 20%;
    margin-top: -3rem;
    @media (max-width: 767px) {
      display: none;
    }
  }
}
