.game-mechanics {
  background-color: $blackColor;
 
    .main {
      text-align: center;
      color: $colorWhite;
      .btn {
        margin-top: 16rem;
        margin-bottom: 2rem;
        background: rgba($color: $orangeColor, $alpha: 0.49);
        color: rgba($color: $colorWhite, $alpha: 0.49);
      }
     
      .top-div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 2rem;
        span {
          color: rgba($color: $colorWhite, $alpha: 0.7);
          margin: 0 1rem;
          font-size: 0.8rem;
        }
      }
     .last {
       margin-bottom: 3rem;
     }
      .bottom-div {
        width: 52rem;
        margin: 0 auto;
        display: flex;
        p {
          border: 1px solid #FF5917;
          border-radius: 0.2rem;
          padding: 0.5rem;
          margin-left: 3px;
          margin-right: 2px;
          width: 23%;
          background: linear-gradient($orangeColor, $lightOrangeColor);
          
        }
        .first-p {
          width: 77%;
          text-align: end;
          margin-right: 0;
          padding-right: 3rem;
        }
      }
    }
    .heading {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    table {
      width: 52rem;
      margin: 0 auto;
      border-collapse: separate;
      border-spacing: 0.3rem 0.3rem;
      th {
        background: linear-gradient($orangeColor, $lightOrangeColor);
        border: 0;
        border-radius: 0.2rem;
        vertical-align: middle;
      }
      td {
        border: 1px solid $orangeColor;
        border-radius: 0.2rem;
        padding: 0.5rem;
        font-weight: 500;
        vertical-align: middle;
       
      }
      p{
        margin-bottom: 0;
      }
  .sec-td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: rgba($color: $colorWhite, $alpha: 0.7);
      font-size: 0.6rem;
    }
  }
  .change-tr {
    td {
      background-color: rgba($color: $colorWhite, $alpha: 0.1);
      vertical-align: middle;
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
  }
    }
    @media(max-width: 767px) {
      .group-img {
        width: 90%;
      }
      .top-div {
        h2 {
          font-size: 1rem;
        }
      }
    }
}