$zincColor: #11162a;
$blackColor: #000000;
$orangeColor: #ff5917;
$orangeVideo: #ff7621;
$colorWhite: #ffffff;
$lightOrangeColor: #eb7c31;
$pinkColor: #ffddcf;
$zincGreenColor: #1c2824;
$darkGreenColor: #1cbc58;
$lightGreenColor: #4fbd10;
$greenColor: #41bc23;
// $grayColor: #353535;
$grayColor: #171717;
$blueColor: #1e96c8;
$royalBlue: #0e1217;
$blushColor: #2adde4;
$lightBlushColor: #00a3bf;
$gray: #252525;
