.landing {
  background: $blackColor;
  .slide-container {
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: 6.8rem;
  }

  .video {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 4rem 0 0 0;
  }

  .video-image {
    position: absolute;
    bottom: 1rem;
    left: 100px;
    z-index: 1;
    width: 80px;
  }

  .text-container {
    position: absolute;
    top: 28%;
    width: 100%;
    text-align: justify;
    color: $colorWhite;
    font-family: $Vicious;
    width: 890px;
    left: 100px;
    margin: 3rem 0 0 0;
  }

  .text-container > h1 {
    font-size: 13rem;
    text-align: justify;
    line-height: 0.8;
  }
  .text-container > h2 {
    font-size: 7.5rem;
    line-height: 0.8;
  }
  .text-container > p {
    font-family: $Montserrat;
    font-size: 25px;
    text-align: justify;
  }
  .text-container > h1 > span {
    color: $orangeVideo;
  }
  .text-container > button {
    color: white;
    background-color: $orangeVideo;
    font-size: 3rem;
    margin-top: 1rem;
    padding: 0 4.5rem;
    cursor: pointer;
    border: 3px solid white;
  }
  .text-container > button:hover {
    background-color: $lightOrangeColor;
  }

  @media (max-width: 2000px) {
    .text-container {
      top: 25%;
      width: 700px;
      left: 120px;
    }

    .video {
      margin: 6rem 0 0 0;
    }

    .text-container > h1 {
      font-size: 10.2rem;
    }

    .text-container > h2 {
      font-size: 5.9rem;
    }

    .text-container > p {
      font-size: 20px;
    }

    .video-image {
      bottom: 1rem;
      left: 120px;
      width: 60px;
    }
    .text-container > button {
      font-size: 2.5rem;
      padding: 0 4.5rem;
    }
  }

  @media (max-width: 1600px) {
    .text-container {
      top: 20%;
      width: 480px;
      left: 150px;
    }

    .text-container > h1 {
      font-size: 7rem;
    }

    .text-container > h2 {
      font-size: 4.05rem;
    }

    .text-container > p {
      font-size: 13px;
    }

    .video {
      margin: 8rem 0 0 0;
    }

    .video-image {
      bottom: 1rem;
      left: 150px;
      width: 50px;
    }
    .text-container > button {
      font-size: 1.6rem;
      padding: 0 3rem;
      margin-top: 0.5rem;
    }
  }
  @media (max-width: 1250px) {
    .text-container {
      top: 20%;
      width: 418px;
      left: 100px;
    }

    .text-container > h1 {
      font-size: 6.1rem;
    }

    .text-container > h2 {
      font-size: 3.5rem;
    }

    .text-container > p {
      font-size: 11px;
    }

    .video {
      margin: 8rem 0 0 0;
    }

    .video-image {
      bottom: 1rem;
      left: 100px;
      width: 40px;
    }
    .text-container > button {
      font-size: 1.3rem;
      padding: 0 2.5rem;
      margin-top: 0.3rem;
    }
  }

  @media (min-width: 1001px) {
    .video-mobile {
      display: none;
    }
  }

  @media (max-width: 1000px) {
    .video-desktop {
      display: none;
    }
    .text-container {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 700px;
      text-align: center;
    }

    .text-container > h1 {
      font-size: 10rem;
      text-align: center;
    }

    .text-container > h2 {
      font-size: 5.8rem;
    }

    .text-container > p {
      text-align: center;
      font-size: 15px;
      margin: auto;
    }

    .video-image {
      bottom: 50px;
      left: 50px;
      width: 50px;
    }
    .text-container > button {
      font-size: 2rem;
      padding: 0 2.5rem;
      margin-top: 1rem;
    }
  }

  @media (max-width: 750px) {
    .text-container {
      top: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 580px;
      text-align: center;
      margin: auto;
    }

    .text-container > h1 {
      font-size: 8.3rem;
    }

    .text-container > h2 {
      font-size: 4.8rem;
    }

    .text-container > p {
      text-align: center;
      font-size: 13px;
    }

    .video-image {
      bottom: 50px;
      left: 50px;
      width: 50px;
    }
    .text-container > button {
      font-size: 2rem;
      padding: 0 2.5rem;
      margin-top: 1rem;
    }
  }

  @media (max-width: 600px) {
    .text-container {
      top: 27%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 580px;
      text-align: center;
      margin: auto;
      width: 440px;
    }

    .text-container > h1 {
      font-size: 6.4rem;
    }

    .text-container > h2 {
      font-size: 3.7rem;
    }

    .text-container > p {
      text-align: center;
      font-size: 12px;
    }
    .video-image {
      bottom: 50px;
      left: 50px;
      width: 50px;
    }
    .text-container > button {
      font-size: 2rem;
      padding: 0 2.5rem;
      margin-top: 1rem;
    }
  }
  @media (max-width: 600px) {
    .text-container {
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 580px;
      text-align: center;
      margin: auto;
      width: 300px;
    }

    .text-container > h1 {
      font-size: 4.3rem;
    }

    .text-container > h2 {
      font-size: 2.5rem;
    }

    .text-container > p {
      text-align: center;
      font-size: 8px;
    }
    .video-image {
      bottom: 30px;
      left: 30px;
      width: 30px;
    }
    .text-container > button {
      font-size: 1.1rem;
      padding: 0 1.5rem;
      margin-top: 1rem;
    }
  }

  .each-slide > div {
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 1rem;
    background-color: $blackColor;
    @media (min-width: 1940px) {
      background-size: 100%;
    }
  }
  .left-btn {
    position: absolute;
    left: 1%;
  }
  .right-btn {
    position: absolute;
    right: 1%;
  }

  .react-slideshow-container + ul.indicators {
    position: relative;
    z-index: 1;
    bottom: 28px;
    margin-bottom: -10px;
    margin-top: 0;
    .each-slideshow-indicator::before {
      background: gainsboro;
    }
    .active::before {
      background: $orangeColor;
    }
  }
  .top-div {
    color: $colorWhite;
    text-align: center;

    span {
      margin-top: 8rem;
      margin-bottom: 6px;
      letter-spacing: 4px;
      font-family: $Roboto;
      display: block;
      font-size: 0.8rem;
      font-weight: 300;
    }

    a {
      cursor: pointer;
    }
    p {
      font-family: $Roboto;
      font-weight: 500;
    }

    .description {
      width: 35%;
      font-size: 1rem;
      margin: 0 auto;
      font-family: $Roboto;
    }

    h1 {
      font-family: $Montserrat;
      font-weight: 600;
      font-size: 1.8rem;
      margin-bottom: 0;
    }

    .heading {
      font-weight: 900;
      font-size: 3rem;
    }

    .btn {
      margin-bottom: 0.8rem;
      font-family: $Roboto;
      font-weight: 500;
    }

    .play-div {
      display: flex;
      margin-top: 2rem;
      justify-content: center;

      a {
        color: $colorWhite;
        font-family: $Roboto;
        font-weight: 400;
        left: -27px;
        img {
          margin-right: 0.5rem;
        }

        &:hover {
          text-decoration: none;
        }
      }
      .play {
        img {
          width: 2.5rem;
        }
      }
    }

    .bottom-div {
      width: 95%;
      margin: 0 auto;
      margin-top: 18rem;
      .gif {
        visibility: hidden;
      }
      @media (max-width: 767px) {
        display: block;
        margin-top: 0rem;
      }
      .game-rating {
        display: flex;
        align-items: center;
        color: $colorWhite;

        @media (max-width: 767px) {
          margin-top: 11.5rem;
        }
        img {
          width: 4rem;
        }
        div {
          margin-left: 1rem;
          text-align: left;
        }

        hr {
          border-top: 3px solid $colorWhite;
          margin-top: 0.3rem;
          margin-bottom: 0.3rem;
        }

        p {
          margin-bottom: 0;
          font-size: 0.8rem;
        }
      }
      .gif {
        display: block;
        margin: 0 auto;
        margin-top: 2.8rem;
        @media (min-width: 2128px) {
          margin-top: 5rem;
        }
        @media (max-width: 767px) {
          margin-top: -10px;
        }
      }
    }

    h3 {
      margin-bottom: 1rem;
      margin-top: 5rem;
    }

    @media (max-width: 767px) {
      margin-top: 9.5rem;

      .description {
        width: 95%;
      }

      h1 {
        font-size: 2rem !important;
      }

      .play-div {
        display: block;
        .play {
          display: block;
        }
        a {
          left: 0;
          margin: 0 auto;
          margin-bottom: 1rem;
        }
      }
    }
  }
  .sec-slide {
    text-align: left;
    margin-top: 8rem;

    h1 {
      margin-top: 8rem;
    }
    .heading {
      color: $orangeColor;
      margin-top: 0;
    }
    .description {
      width: 31rem;
      margin: 0;
      margin-bottom: 1rem;
    }
    .bottom-div {
      visibility: hidden;
      margin-top: 12.55rem;
    }
    .play-div {
      justify-content: initial;
      margin-top: 0;
      a {
        left: 0px;
      }
      .play {
        margin-left: 3rem;
      }
    }
    .hide {
      visibility: hidden;
    }
    h4 {
      margin-top: 6rem;
    }
    .platform {
      margin-bottom: 16.7rem;
    }
    @media (max-width: 767px) {
      margin-top: 0;
      text-align: center;
      .description {
        width: 95%;
        margin: 0 auto;
        margin-bottom: 1rem;
      }
      .platform {
        margin-bottom: 0rem;
        width: 22rem;
      }
      h4 {
        margin-top: 1rem;
        font-size: 1rem;
      }
      .marketbtn {
        margin-top: 2.8rem;
      }
    }
  }
  .trd-div {
    text-align: center;
    .description {
      margin: 0 auto;
      margin-bottom: 1.25rem;
    }
    .paragraph {
      @media (max-width: 767px) {
        margin-bottom: 20rem;
      }
    }
    .last {
      @media (max-width: 767px) {
        margin-top: 16rem;
      }
    }
  }
  .assets {
    display: block;
    margin: 0 0 0 auto;
    margin-top: 4rem;
    width: 100%;
    @media (min-width: 1660px) {
      width: 70%;
    }
    @media (max-width: 767px) {
      margin-top: 0;
    }
  }
  .game-pic {
    @media (max-width: 767px) {
      margin-bottom: 4rem;
    }
  }
  .overlay {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: $colorWhite, $alpha: 0.25);
    backdrop-filter: blur(15px);
    border-radius: 5px;
    text-align: center;
    z-index: 11;
    color: $colorWhite;

    h5 {
      margin-top: 1rem;
    }
    p {
      width: 50%;
      margin: 0 auto;
    }
    .btn {
      background: none;
      color: $colorWhite;
      float: right;
    }
    div {
      display: flex;
      justify-content: space-evenly;
      width: 64%;
      margin: 0 auto;
      padding-bottom: 0.5rem;
      align-items: center;
    }
    span {
      display: block;
      width: 2px;
      height: 3rem;
      background: $colorWhite;
    }
    @media (max-width: 767px) {
      span {
        display: none;
      }
      p {
        width: 90%;
        font-size: 0.8rem;
      }
      div {
        width: auto;
      }
      img {
        width: 7rem;
      }
    }
    .btn {
      img {
        width: 1.5rem;
      }
    }
  }
  .gameMechanics {
    background-color: $blackColor;
    color: $colorWhite;
    text-align: center;
    margin-top: -10px;

    .sub-game {
      background: url("../../images/landing/gameMechanic.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;

      @media (max-width: 767px) {
        background: url("../../images/landing/gameMob.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        margin-top: -4px;
      }
    }

    h1 {
      margin-top: 6rem;
      font-family: $Roboto;
      margin-bottom: 2rem;
    }

    p {
      width: 34%;
      margin: 0 auto;
      font-family: $Roboto;
      font-weight: 500;
      margin-bottom: 3rem;
    }

    img {
      width: 9rem;
    }

    @media (max-width: 767px) {
      p {
        width: 70%;
        font-size: 0.8rem;
      }

      h1 {
        font-size: 1.2rem;
      }
    }

    .row {
      width: 60% !important;
      margin: 0 auto;
      margin-top: 1rem;
      margin-bottom: 11rem;

      p {
        margin-bottom: 0;
        width: auto;
      }

      @media (max-width: 1100px) {
        width: 76% !important;
      }

      @media (max-width: 767px) {
        width: auto !important;

        .last-p {
          margin-bottom: 3rem;
        }
      }
    }
  }

  .market {
    background: url("../../images/landing/monsback.png");
    text-align: center;
    color: $colorWhite;
    background-position: center;

    p {
      width: 40%;
      margin: 0 auto;
      margin-bottom: 2rem;
      font-family: $Roboto;
      font-weight: 400;
    }

    h5 {
      margin-top: 5rem;
      font-family: $Roboto;
      font-weight: 400;
      letter-spacing: 1px;
    }

    h1 {
      font-family: $Roboto;
    }

    h3 {
      margin-top: 2rem;
    }

    @media (max-width: 767px) {
      p {
        width: 75%;
        font-size: 0.8rem;
      }

      h1 {
        font-size: 1.2rem;
      }

      h5 {
        font-size: 1rem;
      }
    }

    .buy {
      left: 0;
    }

    .carousl {
      width: 80%;
      height: 450px;
      margin: 0 auto;
      margin-bottom: 3rem;

      @media (max-width: 767px) {
        width: 100%;
        height: 330px;
        img {
          width: 15rem;
        }
      }

      .css-doq0dk {
        width: 54%;
        margin: auto;

        .css-1fzpoyk {
          opacity: 1 !important;
        }
      }
    }

    .prevArrow {
      position: absolute;
      top: 62%;
      left: 19%;
      cursor: pointer;

      @media (max-width: 767px) {
        top: 91%;
        img {
          width: 3rem;
        }
      }
    }

    .nextArrow {
      position: absolute;
      top: 62%;
      right: 19%;
      cursor: pointer;

      @media (max-width: 767px) {
        top: 91%;
        img {
          width: 3rem;
        }
      }
    }
  }

  .monster-lab {
    background-color: $blackColor;
    color: $colorWhite;
    text-align: center;
    padding-bottom: 5rem;

    .para {
      width: 40%;
      margin: 0 auto;
      font-family: $Roboto;
      font-weight: 300;
      margin-top: 2rem;
      margin-bottom: 3rem;

      @media (max-width: 767px) {
        width: 75%;
        margin: 0 auto;
        font-size: 0.8rem;
      }
    }

    .sub-monster {
      background: url("../../images/landing/labBC.png");
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;

      span {
        letter-spacing: 1px;
        font-family: $Roboto;
        font-weight: 300;
      }
      div {
        text-align: initial;
        width: 40%;

        p {
          width: 70%;
          margin: 0;
        }
      }

      @media (max-width: 767px) {
        display: block;

        div {
          text-align: center;
          width: auto;
          padding-top: 2rem;

          p {
            width: 95%;
            margin: 0 auto;
          }
        }

        img {
          width: 100%;
        }
      }
    }

    .powerd-div {
      display: flex;
      justify-content: space-evenly;
      width: 50%;
      margin: 0 auto;
      margin-bottom: 9rem;
      margin-top: 5rem;

      @media (max-width: 767px) {
        width: 100%;
        img {
          width: 9rem;
        }
      }
    }

    h1 {
      font-family: $Roboto;
    }
    .heading {
      margin-top: 5rem;
    }

    .col-6 {
      margin-bottom: 3rem;
    }

    .btn {
      top: 1.5rem;
    }

    .cooking-div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 7rem;
      margin-bottom: 3rem;

      .detail-div {
        text-align: initial;
        width: 35%;

        h1 {
          margin-top: 0;
        }

        p {
          width: 80%;
          margin: 0;
        }
      }

      .btn {
        font-weight: 500;
      }
      .mob {
        display: none;
      }
      @media (max-width: 767px) {
        display: block;
        .mob {
          display: initial;
          margin-bottom: 3rem;
        }
        .web {
          display: none;
        }
        .detail-div {
          text-align: center;
          width: auto;
          margin-bottom: 6rem;

          h1 {
            font-size: 1.3rem;
          }

          p {
            width: 95%;
            margin: 0 auto;
            font-size: 0.8rem;
          }
        }

        img {
          width: 100%;
        }
      }
    }

    .row {
      width: 55%;
      margin: 0 auto;
      margin-top: 2rem;

      @media (max-width: 767px) {
        width: auto;
      }

      img {
        width: 9rem;
      }

      p {
        width: auto;
      }

      .col {
        @media (max-width: 1024px) {
          margin-bottom: 3rem;
        }
      }
    }

    .button {
      font-weight: 500;
      left: 0;
      margin-bottom: 2rem;
    }
  }

  .secure-div {
    background-color: $blackColor;
    text-align: center;
    color: $colorWhite;

    h1 {
      margin-top: 4rem;
      margin-bottom: 1rem;
      font-family: $Roboto;
    }

    div {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 50%;
      margin: 0 auto;
      margin-bottom: 4rem;

      @media (max-width: 767px) {
        width: auto;
        img {
          width: 8rem;
        }
      }
    }
  }

  .nft-game {
    background: $blackColor;
    .sub {
      background: url("../../images/landing/nft.png");
      background-repeat: no-repeat;
      background-position: center;
      text-align: center;
      color: $colorWhite;
      padding-top: 2px;
      font-family: $Roboto;
      @media (min-width: 1900px) {
        background-size: 100%;
      }
    }

    span {
      display: block;
      margin-top: 2rem;
      letter-spacing: 1px;
      font-family: $Roboto;
      font-weight: 300;
      margin-bottom: 1rem;
    }

    p {
      width: 40%;
      margin: 0 auto;
      margin-bottom: 1rem;
      font-weight: 400;
    }

    h2 {
      font-size: $Roboto;
      font-size: 1.5rem;
    }

    h1 {
      font-size: 5rem;
      font-weight: bold;
      font-style: italic;
    }

    .support-div {
      text-align: center;
      margin-bottom: 2rem;
      img {
        width: 10rem;
        margin-left: 1rem;
      }
      h3 {
        font-size: 1.1rem;
        margin-bottom: 1rem;
        font-family: $Roboto;
      }
    }
    @media (max-width: 767px) {
      span {
        margin-top: 3rem;
        font-size: 0.8rem;
      }

      h1 {
        font-size: 4rem;
      }
      p {
        width: 85%;
        font-size: 0.8rem;
      }

      h2 {
        font-size: 1.2rem;
      }

      img {
        width: 20rem;
      }
      .support-div {
        img {
          width: 5rem;
        }
      }
      .btn {
        margin-bottom: 25rem;
      }
    }
  }
  .road-map {
    background: url("../../images//landing//market.png");
    color: $colorWhite;
    margin-top: 2rem;
    padding-top: 2px;
    .row {
      width: 60rem;
      margin: 0 auto;
      @media (max-width: 767px) {
        width: auto;
      }
    }
    h1 {
      text-align: center;
      font-size: 2.5rem;
      margin-top: 2rem;
      font-family: $Roboto;
    }
    hr {
      width: 60%;
    }
    .heading-box {
      background: $orangeColor;
      text-align: center;
      width: 16rem;
      margin: 0 auto;
      padding: 0.5rem 0 0.5rem 0;
      h5 {
        text-transform: uppercase;
      }
    }
    .body-div {
      width: 16rem;
      background: $blackColor;
      padding: 1rem;
      font-size: 0.9rem;
      margin: 0 auto;
      height: 14rem;
      margin-bottom: 2rem;
      p {
        display: flex;
        align-items: baseline;
        text-align: initial;
        margin-bottom: 0.5rem;
        span {
          margin-left: 0.5rem;
          font-size: 15px;
          font-style: $Montserrat;
          font-weight: 400;
        }
        img {
          width: auto !important;
        }
      }
    }
  }
  .grumpy {
    background: url("../../images/landing/grummy-background.svg");
    background-position: center;
    background-repeat: no-repeat;
    @media (min-width: 1900px) {
      background-size: 100%;
    }

    @media (max-width: 767px) {
      background: $blackColor;
    }

    .sub-grumpy {
      display: flex;
      justify-content: space-evenly;
      width: 80%;
      align-items: center;
      margin: 0 auto;
      margin-top: 5rem;
      margin-bottom: 5rem;
      color: $colorWhite;
      text-align: end;

      span {
        font-family: $Roboto;
        font-weight: 300;
        letter-spacing: 1px;
      }
      @media (max-width: 767px) {
        display: block;
        text-align: center;
        margin-bottom: 4rem;
        margin-top: 4rem;
        width: 100%;

        h1 {
          font-size: 1.4rem;
        }
      }

      div {
        width: 40%;
        font-family: $Roboto;
        font-weight: 400;

        @media (max-width: 767px) {
          width: 100%;
        }

        h1 {
          font-family: $Roboto;
        }

        .btn {
          left: -10px;
        }
      }

      img {
        width: 42%;

        @media (max-width: 767px) {
          width: 90%;
        }
      }
    }
  }

  .team {
    background-color: $blackColor;
    text-align: center;
    font-family: $Roboto;
    color: $colorWhite;

    h1 {
      margin-bottom: 3rem;
      margin-top: 5rem;
      font-family: $Roboto;
    }

    .btn {
      margin-bottom: 4rem;
      background: rgba($color: $orangeColor, $alpha: 0.49);
      color: rgba($color: $colorWhite, $alpha: 0.49);
    }

    .active-btn {
      background-color: $orangeColor;
      color: $colorWhite;
      padding: 0.5rem 2rem;
    }

    .row {
      width: 65%;
      margin: 0 auto;
      margin-bottom: 2rem;

      @media (min-width: 1750px) {
        width: 50%;
      }
      @media (max-width: 1250px) {
        width: 82%;
      }

      .card-div {
        display: flex;
        align-items: flex-start;
        text-align: initial;
        width: 25rem;
        margin: 0 auto;
        margin-bottom: 2rem;

        @media (max-width: 767px) {
          width: auto;
        }

        .profile {
          margin-right: 1rem;
        }

        .heading-div {
          display: flex;
          justify-content: space-between;

          p {
            margin-bottom: 0rem;
          }

          img {
            width: 1.6rem;
          }
        }

        .detail {
          font-size: 0.7rem;
          text-align: justify;
        }
      }
    }
  }

  .partners {
    background: $blackColor;
    text-align: center;
    color: $colorWhite;
    font-family: $Roboto;

    h1 {
      margin-top: 5rem;
      font-family: $Roboto;
      font-size: 2.5rem;
      margin-bottom: 5rem;

      @media (max-width: 767px) {
        font-size: 1.1rem;
      }
    }

    .row {
      width: 78%;
      margin: 0 auto;
      margin-bottom: 2rem;
      img {
        width: 12rem;
      }
      @media (max-width: 1530px) {
        img {
          width: 9rem;
        }
      }
      @media (max-width: 1300px) {
        width: 80%;
        img {
          width: 8rem;
        }
      }
      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 0;
        img {
          width: 10rem;
        }
        .col {
          margin-bottom: 5rem;
        }
        .hide {
          display: none;
        }
      }
    }
  }

  .contact {
    background-color: $blackColor;
    text-align: center;
    color: $colorWhite;

    .box {
      background: url("../../images/landing/contactBck.svg");
      background-position: center;
      background-repeat: no-repeat;
      width: 70%;
      margin: 0 auto;
      margin-top: 10rem;
      margin-bottom: 5rem;
      padding-top: 1px;
      padding-bottom: 1px;

      @media (max-width: 767px) {
        width: 95%;
        margin-top: 0rem;

        .col {
          margin-bottom: 5rem;
        }
      }

      h1 {
        margin-top: 3rem;
        margin-bottom: 1rem;

        @media (max-width: 767px) {
          margin-top: 3rem;
          margin-bottom: 0.5rem;
        }
      }

      p {
        width: 50%;
        margin: 0 auto;
        margin-bottom: 2rem;

        @media (max-width: 767px) {
          width: 95%;
        }
      }

      .row {
        margin: 0 auto 2rem;

        @media (max-width: 767px) {
          width: auto;
          margin: 0 auto;
        }
      }

      form {
        width: 50%;
        margin: 0 auto;

        @media (max-width: 767px) {
          width: 100%;
        }

        .form-control {
          background-color: rgba($color: $pinkColor, $alpha: 0.14);
          border: none;
          color: $colorWhite;

          @media (max-width: 767px) {
            margin-bottom: 1rem;
          }
        }

        ::placeholder {
          color: $colorWhite;
        }

        .btn {
          top: -1rem;
          width: 14%;

          @media (max-width: 767px) {
            top: -4rem;
            width: 22%;
          }
        }
      }
    }
  }
}
