.inventory {

  .form-control {
    background: $gray 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: $colorWhite;
    padding: 0.6rem 0.3rem;
    border: 1px solid $gray;

    &:focus {
      border: 1px solid $orangeColor;
    }
  }

  .upgrade {
    background: linear-gradient($orangeColor, $lightOrangeColor);
    color: $colorWhite;
  }

  .swap-card {
    background-color: $blackColor;

    .heading {
      color: $orangeColor;
    }
  }
}