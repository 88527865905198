@font-face {
  font-family: "Vicious Hunger";
  src: url("../fonts/vicioushunger.ttf") format("truetype");
  /* You can add more font formats as needed */
  font-weight: normal;
  font-style: normal;
}

$Roboto: "Roboto";
$Montserrat: "Montserrat";
$Vicious: "Vicious Hunger";
