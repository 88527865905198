.accumulation {
    .main {
      background: url('../../images/mechanics/back.png');
      background-repeat: no-repeat;
      background-color: #000000;
      background-position: center;
      text-align: center;
      margin-top: 7rem;
      min-height: 65vh;
      color: $colorWhite;
      @media (min-width: 1900px) {
        background-size: 100%;
      }
     .hide {
       visibility: hidden
     }
      .title {
        margin-top: 9rem;
        font-size: 2rem;
        color: $colorWhite;
        font-family: $Montserrat;
        font-weight: 600;
      }
      p {
        margin-bottom: 0;
      }
      .claim-btn {
        background: linear-gradient($darkGreenColor, $lightGreenColor);
      }
      .claim-btn::after {
        border-left: 14px solid $darkGreenColor;
      }
      .claim-btn::before {
        border-right: 14px solid $darkGreenColor;
      }
      table {
        width: 50%;
        margin: 0 auto;
        border-collapse: separate;
        border-spacing: 0 11px;
        @media(max-width: 1240px) {
          width: 62%;
        }
        @media(max-width: 767px) {
          display: none;
        }
        .btn {
          left: 0;
          font-weight: 400;
        }
       
        td, th {
          border-top: 0;
          border-bottom: 0;
          vertical-align: middle;
        }
        .top-tr {
          background: linear-gradient($orangeColor, $lightOrangeColor);
          border: 1px solid $orangeColor;
        }
        .claim-tr {
          background: rgba($color: $greenColor, $alpha: 0.1);
          td {
            border-top: 1px solid rgba($color: $greenColor, $alpha: 0.8);
            border-bottom: 1px solid rgba($color: $greenColor, $alpha: 0.8);
          }
          .claim-td-left {
            border-left: 1px solid rgba($color: $greenColor, $alpha: 0.8);
          }
          .claim-td-right {
            border-right: 1px solid rgba($color: $greenColor, $alpha: 0.8);
          }
         
        }
        
        .tr {
          background: rgba($color: $colorWhite, $alpha: 0.1);
          border: 1px solid rgba($color: $colorWhite, $alpha: 0.8);
          td {
            border-top: 1px solid rgba($color: $colorWhite, $alpha: 0.8);
            border-bottom: 1px solid rgba($color: $colorWhite, $alpha: 0.8);
          }
          .td-left {
            border-left: 1px solid rgba($color: $colorWhite, $alpha: 0.8);
          }
          .td-right {
            border-right: 1px solid rgba($color: $colorWhite, $alpha: 0.8);
          }
        }
      }
      .mobile-view {
        display: none;
        @media(max-width: 767px) {
          display: initial;
        }
        .box {
          width: 90%;
          margin: 0 auto;
          div{
          display: flex;
          padding-top: 2rem;
          padding-bottom: 1rem;
          justify-content: space-around;
          align-items: baseline;
          .btn {
            left: 0;
            font-weight: 400;
          }
          .time {
            padding: 0.7rem;
          }
        }
      }
      .first-box {
        background: rgba($color: $greenColor, $alpha: 0.3);
        border: 1px solid rgba($color: $greenColor, $alpha: 0.8);
        border-radius: 0.5rem;
        margin-bottom: 1rem;
      }
      .sec-box {
        background: rgba($color: $colorWhite, $alpha: 0.1);
        border: 1px solid rgba($color: $colorWhite, $alpha: 0.8);
        border-radius: 0.5rem;
        margin-bottom: 1rem;
      }
      }
      
      #nft-inventory-list {
        max-height: 700px;

        .inventory-loader {
          background: rgba($color: $colorWhite, $alpha: 0.1);
          border: 1px solid rgba($color: $colorWhite, $alpha: 0.8);
        }
      }
    }
}