.buy-monos {

  .main {
    background: url('../../images/buy/backs.png');
    background-position: center;
    background-repeat: no-repeat;
    background-color: $blackColor;
    background-size: 100%;
    margin-top: -2.5rem;
    text-align: center;
    color: $colorWhite;

    .heading {
      margin-top: 14rem;
      font-size: 1.8rem;
      display: block;
    }
    h1, h4 {
      font-family: $Montserrat;
      font-weight: bold;
    }
    p {
      width: 40%;
      margin: 0 auto;
    }
   
    .heading-box {
      padding: 0.5rem;
      margin: 0 auto;
      border-radius: 5px;
      background: $orangeColor;
      margin-left: 2rem;
      margin-bottom: 0.5rem;
      h5 {
        margin-bottom: 0;
      }
    }
    .public-box {
      padding: 0.5rem;
      border-radius: 5px;
      margin: 0 auto;
      background: $orangeColor;
      margin-bottom: 0.5rem;
      margin-left: 2rem;
      h5 {
        margin-bottom: 0;
      }
    }
    .cards-row {
      width: 65%;
      margin: 0 auto;
      margin-top: 2rem;
      margin-bottom: 2rem;
      @media(max-width:1400px) {
        width: 70%;
      }
      @media(max-width:1200px) {
        width: 85%;
      }
      @media(min-width:2000px) {
        width: 55%;
      }
    }
   
    .card {
      background: rgba($color: $colorWhite, $alpha: 0.25);
      border: 2px solid $orangeColor;
      backdrop-filter: blur(15px);
      margin: 0 auto;
      padding-bottom: 1rem;
      padding-top: 1rem;
      margin-left: 2rem;
     
      h1 {
        font-size: 1.3rem;
      }
      .btn {
        width: auto;
        margin: 0 auto;
      }
      p {
        width: auto;
      }
      .progress-p {
        text-align: end;
        margin-bottom: 1rem;
        width: 80%;
      }
      span {
        margin-top: 1rem;
      }
      .btn {
        font-weight: 500;
      }
      .time-p {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      .mid-div {
        display: flex;
        justify-content: space-evenly;
        margin-top: 1rem;
        span {
          font-size: 0.6rem;
        }
      }
      .hide {
        visibility: hidden;
      }
      .progress {
        background-color: rgba($color: $orangeColor, $alpha: 0.25);
        height: 0.5rem;
        width: 80%;
        margin: 0 auto;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
      }
 
      .progress-bar {
        background: linear-gradient($orangeColor, $lightOrangeColor) !important;
      }
    }
    .color-card {
      background: rgba($color: $orangeColor, $alpha: 0.25);
      .amount-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.8rem;
        width: 85%;
        margin: 0 auto;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
        
        span {
          margin-top: 0;
          font-size: 0.7rem;
        }
        p {
          margin: 0;
        }
        @media (min-width: 1550px) {
          font-size: 1rem;
          span {
            font-size: 0.8rem;
          }
        }
      }
      .max {
        background-color: $orangeColor;
        color: $colorWhite;
        position: relative;
        top: -2rem;
        right: -31%;
        font-size: 0.8rem;
        padding-bottom: 0.2rem;
        padding-top: 0.2rem;
      }
      .disclimer {
        text-decoration: underline;
        cursor: pointer;
      }
      .form-control {
        width: 85%;
        color: $orangeColor;
        font-weight: bold;
        margin: 0 auto;
      }
      .rc-checkbox-checked {
      .rc-checkbox-inner {
        border-radius: none;
        border: 0;
        background-color: $orangeColor;

        &:after {
          border: none;
        }
      }}
      .swap {
        width: 2rem;
        display: block;
        margin: 0 auto;
      }
      ::placeholder {
        color: rgba($color: $orangeColor, $alpha: 0.5);
        font-weight: bold;
      }
      label {
        display: flex;
        width: 85%;
        margin: 0 auto;
        text-align: initial;
        margin-top: -0.5rem;
        font-size: 0.9rem;
        margin-bottom: 1rem;
        span {
          margin-top: 2px;
          margin-right: 0.2rem;
        }
      }
    }
    .partners-div {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 1rem;
      margin-bottom: 1rem;
      span {
        display: block;
        width: 1px;
        height: 2rem;
        margin-top: 0;
        background-color: $colorWhite;
      }
      img {
        width: 7rem;
      }
    }
    .first-card {
      .time-p {
        visibility: hidden;
      }
      .text {
        width: 15rem;
        margin: 0 auto;
        margin-top: 1rem;
      }
    }
    .last-card {
      .time-p{
        visibility: hidden;
      }
    }
    .bottome-div {
      display: flex;
      margin-top: 1rem;
      justify-content: space-between;
      align-items: center;
      margin-left: 2rem;
      padding: 1rem;
      background: rgba($color: $colorWhite, $alpha: 0.25);
      border: 1px solid $orangeColor;
      border-radius: 0.25rem;
      p {
        width: 42%;
        text-align: center;
        .warning {
          display: block;
        }
      }
      .time-p {
        width: 32%;
      }
    }
    @media (max-width:767px) {
      background: url('../../images/buy/mobBack.png');
      p, .cards-row {
       width: 95% !important;
      }
      .card, .heading-box, .public-box {
        margin-bottom: 1rem !important;
        width: 18rem;
      }
      .bottome-div {
        display: block;
        width: 18rem;
        font-size: 0.8rem;
        padding: 0.5rem;
        .time-p {
          margin-top: 2rem;
          margin-bottom: 1rem;
          width: auto;
          text-align: center;
        }
        img {
          margin-bottom: 1rem;
        }
      }
     }

     .accumulations {
       .vest-item {
         line-height: 2.3;
       }
     }
  }
}