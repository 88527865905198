.rent-nft {
  border-radius: 0.3rem;
  background: $royalBlue;
  border: 3px solid $orangeColor;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 1rem;
  @media (min-width: 1450px) {
    width: 90%;
    margin-right: 0;
  }
  h2 {
    color: $orangeColor;
    font-family: $Montserrat;
    font-weight: 800;
  }
 
  .description {
    width: 60%;
    margin: 0 auto;
    margin-top: 1rem;
    font-size: 00.8rem;
    font-family: $Roboto;
    font-weight: 400;
    @media (max-width: 767px) {
      width: auto;
    }
  }
}