.staking {
  
  a{
    color: $orangeColor;
  }

  background: url('../../images/buy/backs.png');

  .main {
    color: $colorWhite;
    background: rgba($color: $blackColor, $alpha: 0.4);
    text-align: center;
    margin-top: 10rem;

    .top-btn {
      margin-top: 4rem;
      margin-bottom: 2rem;
      background: rgba($color: $orangeColor, $alpha: 0.49);
      color: rgba($color: $colorWhite, $alpha: 0.49);
    }

    h2 {
      font-family: $Montserrat;
      font-weight: 600;
    }

    p {
      img {
        width: 2rem;
      }

      span {
        font-size: 1.2rem;
        font-family: $Montserrat;
        font-weight: 600;
      }
    }

    .main-box {
      display: flex;
      margin: 0 auto;
      width: 60rem;
      margin-top: 1rem;
      margin-bottom: 2rem;
      justify-content: space-between;

      .box {
        background: $blackColor;
        width: 52%;
        padding: 1rem;
        border-radius: 0.3rem;

        h5 {
          color: $orangeColor;
          font-family: $Montserrat;
          font-weight: 600;
        }

        .btn-div {
          margin-bottom: 1rem;
          margin-top: 1rem;

          .btn,
          .form-control {
            background: $gray 0% 0% no-repeat padding-box;
            border-radius: 5px;
            color: $colorWhite;
            width: 8rem;
            margin-left: 0.5rem;
            padding: 0.6rem 0.3rem;
            margin-bottom: 0.5rem;
          }

          .form-control {
            border: 1px solid $gray;
            text-align: center;
            display: inline-block;
            padding: 1.3rem 0.3rem;
          }

          .active {
            border-color: $orangeColor;
          }
        }

        .hr-div {
          display: flex;

          hr {
            border-top: 1px solid rgba($color: $colorWhite, $alpha: 0.7);
            width: 39%;

          }
        }

        label {
          display: flex;
          align-items: baseline;
          text-align: initial;
          margin-top: 2rem;

          div {
            margin-left: 0.5rem;
            font-family: $Roboto;
            font-weight: 300;
            font-size: 0.9rem;
            color: rgba($color: $colorWhite, $alpha: 0.8);

            a {
              color: rgba($color: $colorWhite, $alpha: 0.8);
              text-decoration: underline;
            }
          }
        }

        .upgrade {
          background: linear-gradient($orangeColor, $lightOrangeColor);
          color: $colorWhite;
        }

        .nft-list-1 {
          min-height: 200px;
          height: auto;
          max-height: 30vh;
          overflow: auto;
        }

        .nft-list-2 {
          min-height: 200px;
          height: auto;
          max-height: 70vh;
          overflow: auto;
        }
      }

      &.staking {
        width: 55rem;
        .box-right {
          width: 75%;
          background: none;
          padding: 0;
          margin: 0 auto;

          p {
            color: rgba($color: $colorWhite, $alpha: 0.7);

            span {
              color: $colorWhite;
              font-size: 0.9rem;

            }
          }

          .sub-right,
          .last-box {
            background: $blackColor;
            padding: 1rem;
            border-radius: 0.3rem;

            h1 {
              font-family: $Montserrat;
              font-weight: 600;
              font-size: 2.2rem;

              img {
                width: 2rem;
              }
            }
          }

          .last-box {
            margin-top: 0.5rem;

            .btn {
              margin-bottom: 1rem;
            }
          }

          .mid-div {
            display: flex;
            justify-content: space-between;
            font-size: 0.9rem;
            text-align: end;
            margin-top: 1rem;
            margin-bottom: 1rem;
            color: rgba($color: $colorWhite, $alpha: 0.8);

            p {
              margin-bottom: 0;
            }
          }
        }
      }

      @media (max-width: 767px) {
        width: auto !important;
        display: block;

        .box {
          width: 95% !important;
          margin: 0 auto;
        }
      }
    }

    h1 {
      color: $orangeColor;
      font-family: $Montserrat;
      font-weight: bold;
    }

    h5 {
      font-family: $Montserrat;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    .scroll-right {
      margin-bottom: 1rem;
    }

    table.reward-info {
      width: 58rem;
      margin: 0 auto;
      margin-bottom: 2rem;
      border-collapse: separate;
      border-spacing: 0.3rem 0.3rem;

      th {
        background: linear-gradient($orangeColor, $lightOrangeColor);
        border: 0;
        border-radius: 0.2rem;
        vertical-align: middle;

        span {
          font-size: 0.8rem;
          font-weight: 500;
        }
      }

      td {
        border: 1px solid $blackColor;
        background: $blackColor;
        border-radius: 0.2rem;
        text-transform: uppercase;
        padding: 0.5rem;
        font-weight: 500;
        vertical-align: middle;

        span {
          color: $orangeColor;
        }
      }

      .last {
        width: 30%;
      }
    }
  }
}

.card-1 {
  display: flex;
  margin: 4px 0;
  padding: 0px 10px;
  background: $gray 0% 0% no-repeat;
  align-items: center;
  border-radius: 0.3rem;

}

.card-item {
  margin: 5px 5px;
  width: 60%;
  flex: 1 1 auto;

  img{
    width: 55px;
    height: 55px;
  }

  &:first-child{
    width: 20%;
  }

  &:last-child{
    width: 20%;
  }

}

.counter-btns {
  display: flex;
  justify-content: center;

  .btn:first-child{
    z-index: 1;
    flex-shrink: 1;
    margin-right: -3px;
  }
  
  .btn:last-child{
    z-index: 1;
    flex-shrink: 1;
    margin-left: -3px;
  }
  
  input {
    padding: 3px 6px;
    flex-shrink: 2;
    max-width: 50px;
    padding-left: 15px;
    background: rgba(77, 52, 38, 0.8);
    color: $colorWhite;
    border-radius: 0;
    border-width: 0;
  }
}

.nft-rewards {
  border-collapse: separate;
  border-spacing: 0rem 0.5rem;

  thead {
    th {
      border: none;
      font-weight: 500;
    }
  }
}

.nft-reward-row {
  background: $royalBlue;

  &:focus{
    border: 1px solid $orangeColor;

    td {
      border-top: 1px solid $orangeColor;
      border-bottom: 1px solid $orangeColor;

      &:first-child {
        border-left: 1px solid $orangeColor;
      }
  
      &:last-child {
        border-right: 1px solid $orangeColor;
      }
    }
  }
  
  td {
    background: $royalBlue;
    vertical-align: middle;
    border: none;

    &:first-child {
      border-top-left-radius: 0.3rem;
      border-bottom-left-radius: 0.3rem;
    }

    &:last-child {
      border-top-right-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
    }
  }

  img{
    width: 60px;
    height: 60px;
  }
}

.nft-staking {
  .box-left {
    width: 35% !important;
  }
  .box-right {
    width: 65% !important;
  }
  
}
.pvp-launch {
  width: 50%;

  @media(max-width: 1280px) {
    width: 75%;
  }
  @media(max-width: 768px) {
    width: 90%;
  }
}